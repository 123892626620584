@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap");

body {
  margin: 0;
  font-family: Open sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 350px;
}

code {
  font-family: Open sans-serif;
}
