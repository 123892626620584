.sticky {
  height: 10vh;
  display: flex !important;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #d9dde1;
  z-index: 1;
}
.subheader {
  height: 13vh;
}
.subs {
  margin-left: 200px;
  display: flex;
  align-items: center;
}
.subs .option {
  margin-left: 25px;
  align-items: center;
}
.subs h1 {
  font-size: 16px;
}
.subs h3 {
  color: #151515;
  font-size: 16px;
}
.logo {
  cursor: pointer;
  margin-left: 46px;
  margin-top: 10px;
}
.mership {
  height: 3rem;
  border-radius: 3px;
}
.subheader h1 {
  margin-left: 270px;
  margin-top: 15px;
  margin-bottom: 13px;
  font-family: "General Sans";
  font-style: "Semibold";
  font-weight: 500;
  font-size: 38px;
  line-height: 114%;
  line-height: 48px;
  color: #0d0d0d;
}
.subheader h3 {
  font-family: "Inter";
  font-style: "medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  line-height: 14px;
  color: #0d0d0d;
}
.option {
  height: 70px;
  cursor: pointer;
  display: flex;
  gap: 40px;
  margin-left: 255px;
  font-family: "Inter";
  font-style: "medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 114%;
  line-height: 18px;
}
.option h3 {
  font-size: 16px;
}
.option a {
  text-decoration: none;
  color: #151515;
}

.about-img {
  width: 100%;
}
.about-img img {
  width: 100%;
}
.about-us {
  width: 100%;
  display: flex;
  justify-content: center;
}
.head {
  width: 100%;
  display: flex;
  justify-content: center;
}
.head-contain {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  gap: 115px;
  width: 978px;
}
.about {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  gap: 115px;
  width: 978px;
}
.story {
  width: 150%;
}
.content2 {
  display: flex;
  width: 100%;
  /* height: 30vh; */
  background-color: white;
  color: black;

  justify-content: center;
}

.conn {
  border-top: 1px solid rgba(217, 221, 225, 1);
  margin-top: 80px;
  width: 978px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 80px;
}
.show {
  color: #a0a3a7;
}
.show .first-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 114%;
  color: black;
}
.show .sec-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 114%;
  margin-left: 10px;
}
.p1 {
  font-size: 48px;
  margin-top: 34px;
  color: black;
}
.container {
  display: flex;
  width: 100%;
}
.container1 {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
.container1 h1 {
  margin-top: 129px;
  margin-left: 325px;
  font-family: "General Sans";
  font-style: "medium";
  font-weight: 500;
  font-size: 32px;
  line-height: 114%;
  line-height: 36px;
  color: #0d0d0d;
}
.container h1 {
  margin-top: 129px;
  margin-left: 90px;
  font-family: "General Sans";
  font-style: "medium";
  font-weight: 500;
  font-size: 32px;
  line-height: 114%;
  line-height: 36px;
  color: #0d0d0d;
}
.container1 p {
  font-family: "Open Sans";
  font-style: "regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 114%;
  line-height: 16px;
  color: #a0a3a7;
  width: 378px;
  margin-top: 27px;
  margin-left: 325px;
}
.container p {
  font-family: "Open Sans";
  font-style: "regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 114%;
  line-height: 16px;
  color: #a0a3a7;
  width: 378px;
  margin-top: 27px;
  margin-left: 90px;
}
.pic {
  object-fit: cover;
  width: 50%;
  float: left;
}
.pic img {
  object-fit: cover;
  width: 100%;
  float: left;
}
.text {
  /* height: 400px; */
  width: 50%;
  float: right;
}
.pic1 {
  width: 50%;
  float: right;
}
.pic1 img {
  width: 100%;
  float: right;
}
.text1 {
  /* height: 400px; */
  width: 50%;
  float: left;
}

.email {
  width: 100%;
  background: #f5f7f9;
  display: flex;
  justify-content: center;
}
.Con {
  display: flex;
  height: 185px;
  width: 978px;
  align-items: center;
  border-bottom: 1px solid #d9dde1;
}
.mail {
  width: 448px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 114%;
}

.inputt {
  padding: 20px;
  width: 406px;
  height: 55px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 114%;
  display: flex;
  align-items: center;
}
