/* .All {
  display: flex;
  flex-direction: column;
  min-height: 100%;
} */
/* body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
} */
.brands {
  margin-right: 50px;
  display: flex;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 114%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open sans";
}
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1px;
  background-repeat: no-repeat;
  height: 100vh;
  /* width: 100%; */
  object-fit: cover;
  overflow: hidden;
  background: url("../../assets/home2.png");
  background-size: cover;
}
.home h1 {
  font-family: "General Sans";
  cursor: pointer;
  font-style: medium;
  font-weight: 500;
  font-size: 48px;
  line-height: 114%;
  color: #ffffff;
}
.btn {
  color: white;
  display: inline-flex;
  gap: 15px;
  margin-top: 24px;
}
.btn1 {
  width: 148px;
  height: 34px;
  color: #f5f7f9;
  background-color: transparent;
  border: 1px solid #f5f7f9;
  font-family: "Inter";
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
}
.btn2 {
  background-color: transparent;
  cursor: pointer;
  width: 148px;
  height: 34px;
  color: #f5f7f9;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 114%;
}
.three-image {
  display: flex;
  margin-bottom: 1px;
}

.three-image h1 {
  color: #ffffff;
  flex-direction: row;
  justify-content: center;
  font-family: "General Sans";
  font-style: medium;
  font-weight: 500;
  font-size: 38px;
  line-height: 114%;
  cursor: pointer;
}
.model {
  /* background-image: url("../../assets/aeoluus.png"); */
  background: url("../../assets/aeolus.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.model1 {
  background-repeat: no-repeat;
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  /* background-image: url("../../assets/voyahh.png"); */
  background: url("../../assets/f.png");
  background-size: cover;
}
.model2 {
  background-repeat: no-repeat;
  height: 90vh;
  width: 100%;
  /* background-image: url("../../assets/voyahh.png"); */
  background: url("../../assets/forthi.png");
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.model:hover {
  transform: scale(1.01);
}
.model1:hover {
  transform: scale(1.01);
}
.model2:hover {
  transform: scale(1.01);
}

.container {
  display: flex;
  width: 100%;
}
.container1 {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
.container1 h1 {
  margin-top: 129px;
  margin-left: 325px;
  font-family: "General Sans";
  font-style: "medium";
  font-weight: 500;
  font-size: 32px;
  line-height: 114%;
  line-height: 36px;
  color: #0d0d0d;
}
.container h1 {
  margin-top: 129px;
  margin-left: 90px;
  font-family: "General Sans";
  font-style: "medium";
  font-weight: 500;
  font-size: 32px;
  line-height: 114%;
  line-height: 36px;
  color: #0d0d0d;
}
.container1 p {
  font-family: "Open Sans";
  font-style: "regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 114%;
  line-height: 16px;
  color: #a0a3a7;
  width: 378px;
  margin-top: 27px;
  margin-left: 325px;
}
.container p {
  font-family: "Open Sans";
  font-style: "regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 114%;
  line-height: 16px;
  color: #a0a3a7;
  width: 378px;
  margin-top: 27px;
  margin-left: 90px;
}
.pic {
  object-fit: cover;
  width: 50%;
  float: left;
}
.pic img {
  object-fit: cover;
  width: 100%;
  float: left;
}
.text {
  /* height: 400px; */
  width: 50%;
  float: right;
}
.pic1 {
  width: 50%;
  float: right;
}
.pic1 img {
  width: 100%;
  float: right;
}
.text1 {
  /* height: 400px; */
  width: 50%;
  float: left;
}
.pic2 {
  width: 50%;
  float: left;
}
.pic2 img {
  width: 100%;
  float: left;
}
.text2 {
  /* height: 400px; */
  width: 50%;
  float: right;
}
.pic3 {
  width: 50%;
  float: right;
}
.pic3 img {
  width: 100%;
  float: right;
}
.text3 {
  /* height: 400px; */
  width: 50%;
  float: left;
}
.pic4 {
  width: 50%;
  float: left;
}
.pic4 img {
  width: 100%;
  float: left;
  margin-bottom: 120px;
}
.text4 {
  /* height: 400px; */
  width: 50%;
  float: right;
}

.about {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  height: 745px;
  background: #f5f7f9;
}
.content {
  width: 100%;
}
.rightImg {
  width: 50%;
  float: right;
}
.rightImg img {
  float: right;
  width: 100%;
}
.leftcontent h1 {
  font-family: "General Sans";
  font-style: "medium";
  font-weight: 500;
  font-size: 44px;
  line-height: 114%;
  line-height: 55px;
  color: #151515;
  margin-bottom: 38px;
  margin-right: 180px;
}
.leftcontent {
  height: 44vh;
  float: left;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.leftcontent p {
  font-family: "Open Sans";
  font-style: "regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 114%;
  line-height: 17px;
  color: #888888;
  margin-right: 92px;
  width: 447px;
}
.arrow {
  margin-right: 131px;
  margin-top: 38px;
}
.section {
  background-color: #d9d9d9;
  display: flex;
  height: 720px;
  overflow: hidden;
  transition: 0.4s;
}
.content {
  height: 500px;
  margin: 0 auto;
  max-width: 1080px;
  padding: 4rem;
  width: 100%;
}
.title-wrapper {
  max-width: 60%;
}
.title {
  font-size: 3.6rem;
  font-weight: 700;
}
.text-wrapper {
  max-width: 50%;
}
.description {
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-bottom: 3rem;
}

.icons {
  margin-bottom: 140px;
  margin-top: 100px;
  gap: 70px;
  display: flex;
  justify-content: center;
  object-fit: cover;
}
.icons img {
  object-fit: contain;
}

.Event {
  width: 100%;
  display: flex;
  justify-content: center;
}
.containeR {
  margin-top: 127px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 138%;
  height: 700px;
  display: flex;
  width: 978px;
}
.description p {
  margin-top: 127px;
  font-family: "Open Sans";
  font-style: "light";
  font-weight: 300;
  font-size: 14px;
  line-height: 114%;
  line-height: 16px;
  color: #151515;
  height: 146px;
  margin-top: 42px;
  width: 203px;
}
.description h1 {
  color: #0d0d0d;
  margin-top: 35px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 114%;
}
.pagination {
  margin-top: 54px;
  display: inline-block;
  width: 264px;
  font-size: 16px;
}

.pagination a {
  color: black;
  float: left;
  padding: 6px 12px;
  text-decoration: none;
}

.pagination a.active {
  background-color: black;
  color: white;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}
.Card1 {
  /* background: #f5f7f9; */
  width: 416px;
  /* height: 432px; */
  /* border-color: rgb(95, 95, 95); */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  /* transition: 0.5s all ease-in-out;
  transform: scale(1); */
  overflow: hidden;
  margin: 11px;
  margin-top: 28px;
}

.card-image1 img {
  width: 416px;
  height: 432px;
  object-fit: cover;
}
.card-image1 p {
  color: #151515;
  margin-top: 8px;
}
.card-image1 button {
  margin-top: 8px;
  border: none;
  color: #a0a3a7;
}
.email {
  width: 100%;
  background: #f5f7f9;
  display: flex;
  justify-content: center;
}
.Con {
  display: flex;
  height: 185px;
  width: 978px;
  align-items: center;
  border-bottom: 1px solid #d9dde1;
}
.mail {
  width: 448px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 114%;
}

.inputt {
  padding: 20px;
  width: 406px;
  height: 55px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 114%;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1920px) {
  .home h1 {
    letter-spacing: 20px;
    font-size: 48px;
  }
  .btn {
    margin-top: 40px;
  }
  .btn1 {
    width: 200px;
    font-size: 21px;
  }
  .btn2 {
    width: 200px;
    font-size: 21px;
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .text h1 {
    margin: 0;
    font-size: 48px;
  }
  .text p {
    width: 600px;
    margin-top: 30px;
    font-size: 24px;
    margin-left: 0;
    line-height: 32px;
  }
  .leftcontent h1 {
    font-size: 48px;
  }
  .leftcontent p {
    font-size: 24px;
    margin-right: 145px;
    line-height: 36px;
  }
  .Con {
    width: 1078px;
    gap: 50px;
  }
  .mail {
    font-size: 32px;
  }
  .inputt {
    font-size: 32px;
    width: 100%;
  }
}
@media screen and (min-width: 770px) and (max-width: 1440px) {
  .home h1 {
    letter-spacing: 10px;
  }
  .pic img {
    width: 100%;
  }
  .pic {
    width: 50%;
  }
  .text {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .model {
    height: 60vh;
  }
  .model1 {
    height: 60vh;
  }
  .model2 {
    height: 60vh;
  }
  .text h1 {
    margin: 0;
  }
  .text p {
    margin: 0;
  }
  .icons {
    width: 100%;
  }
  .icons img {
    width: 25%;
  }
  .inputt {
    width: 100%;
  }
}
@media screen and (max-width: 770px) {
  .home {
    display: flex;
    height: 44vh;
    background-repeat: no-repeat;
  }

  .home h1 {
    letter-spacing: 5px;
    font-family: "General Sans";
    font-size: 16px;
    color: #ffffff;
    /* border: 1px solid green; */
  }
  .btn {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
  }
  .btn1 {
    font-size: 12px;
  }
  .btn2 {
    font-size: 12px;
  }
  .text {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    width: 95%;
    text-align: center;
  }
  .pic {
    width: 95%;
  }
  .pic1 {
    width: 95%;
  }
  .pic2 {
    width: 95%;
  }
  .pic3 {
    width: 95%;
  }
  .pic4 {
    width: 95%;
  }
  .pic4 img {
    margin-bottom: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container h1 {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 28px;
  }
  .container p {
    margin: 9px 9px 9px 9px;
    font-size: 14px;
    width: 95%;
  }
  .container1 h1 {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 28px;
  }
  .container1 p {
    margin: 9px 9px 9px 9px;
    font-size: 14px;
    width: 95%;
  }
  .container1 {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
  .three-image {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    /* flex-direction: column; */
  }
  .three-image h1 {
    font-size: 24px;
  }
  .model {
    width: 33%;
    height: 40vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .model1 {
    width: 33%;
    height: 40vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .model2 {
    width: 33%;
    height: 40vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .about {
    margin-top: 30px;
    display: flex;
    flex-direction: column-reverse;
    height: 75vh;
    justify-content: center;
  }
  .rightImg {
    width: 95%;
  }
  .leftcontent {
    display: flex;
    width: 100%;
    align-items: center;
    height: 30vh;
  }
  .leftcontent h1 {
    font-size: 24px;
    margin-right: 0;
  }
  .leftcontent p {
    font-size: 14px;
    width: 85%;
    margin-right: 0;
    text-align: center;
  }
  .arrow {
    width: 27px;
    height: 27px;
    margin-right: 0;
    margin-top: 0;
  }
  .icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  .icons img {
    height: 5vh;
    width: 50%;
  }
  .Con {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .mail {
    width: 50%;
    font-size: 12px;
  }
  .inputt {
    margin-top: 5px;
    padding: 10px;
    width: 100%;
    height: 30px;
    font-size: 9px;
  }
}
