.increase-size {
  width: 741px;
  height: 457px;
  margin-block: 20px;
  padding: 60px 0;
  margin-block: 33px;
  grid-gap: 12rem;
  margin: auto;
  transform: translate3d(0, 0, 0);
  animation: travel 20s ease-in-out infinite;
}
.size {
  width: 741px;
  height: 457px;
  object-fit: cover;
  grid-gap: 1.2rem;
}
.size1 {
  width: 1078px;
  height: 670px;
  object-fit: cover;
  grid-gap: 1.2rem;
  animation: travel 20s ease-in-out infinite;
}
.size2 {
  width: 551px;
  height: 670px;
  object-fit: cover;
  grid-gap: 1.2rem;
}
.text {
  box-sizing: border-box;
  padding-top: 38px;
  color: #a0a3a7;
  font-size: 16px;
  font-family: "Open Sans";
}

.gallery {
  max-width: 1800px;
  /* margin-left: 325px; */
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  /* padding-left: 16px;
	padding-right: 16px; */
  margin-top: 200px;
}
.gallery h1 {
  margin-left: 330px;
  font-size: 42px;
}
@media screen and (min-width: 1920px) {
}
@media screen and (min-width: 770px) and (max-width: 1440px) {
  .text {
    width: 200px;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
}
@media screen and (max-width: 770px) {
  .wrapper h1 {
    margin-left: 50px;
    font-size: 42px;
  }
  .text {
    width: 50%;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
}
