.sticky {
  height: 10vh;
  display: flex !important;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #d9dde1;
  z-index: 1;
}
.subheader {
  height: 13vh;
}
.subs {
  margin-left: 200px;
  display: flex;
  align-items: center;
}
.subs .option {
  margin-left: 25px;
  align-items: center;
}
.subs h1 {
  font-size: 16px;
}
.subs h3 {
  color: #151515;
  font-size: 16px;
}
.logo {
  cursor: pointer;
  margin-left: 46px;
  margin-top: 10px;
}
.mership {
  height: 3rem;
  border-radius: 3px;
}
.subheader h1 {
  margin-left: 255px;
  margin-top: 15px;
  margin-bottom: 13px;
  font-family: "General Sans";
  font-style: "Semibold";
  font-weight: 500;
  font-size: 38px;
  line-height: 114%;
  line-height: 48px;
  color: #0d0d0d;
}
.option {
  height: 70px;
  cursor: pointer;
  display: flex;
  gap: 40px;
  margin-left: 255px;
  font-family: "Inter";
  font-style: "medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 114%;
  line-height: 18px;
}
.option h3 {
  font-size: 16px;
}
.option a {
  text-decoration: none;
  color: #a0a3a7;
}
.main-two {
  display: flex;
  justify-content: space-around;
}
.interior-text {
  display: flex;
  justify-content: center;
}
.container {
  width: 978px;
}
.interior-text h2 {
  margin-top: 42px;
}
.interior-text p {
  width: 600px;
  margin-top: 8px;
}
.three-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cards {
  width: 100%;
  display: flex;
}
.Card {
  width: 978px;
  width: 352px;
  height: 458px;

  transition: 0.5s all ease-in-out;
  transform: scale(1);
  overflow: hidden;
  margin: 11px;
  margin-top: 28px;
}

.card-image img {
  width: 352px;
  height: 300px;
  object-fit: cover;
}

/* .Card:hover {
    cursor: pointer;
    transform: scale(0.9);
  } */
.text1 {
  margin-top: 18px;
  margin-left: 17px;
  margin-right: 16px;
  font-family: Verdana, sans-serif;
  width: 300px;
  height: 158px;
  text-align: start;
}
.text1 h3 {
  font-style: normal;
  line-height: 138%;
  color: #151515;
}
.pi1 {
  margin-top: 8px;
  width: 300px;
  /* text-align: justify; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 138%;
  color: #a0a3a7;
}
.vehicles {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
}
.contain {
  width: 978px;
  display: flex;
  /* position: relative; */
  /* color: white; */
  flex-direction: column;
  align-items: center;

  /* color: white; */
}
.contain h1 {
  width: 978px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 114%;
  margin-bottom: 28px;
  color: #0d0d0d;
}
.contain p {
  max-width: 978px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 138%;
  margin-bottom: 28px;
  color: #151515;
}

.big {
  display: flex;
  gap: 20px;
}
.box {
  display: flex;
  flex-direction: column;

  width: 50%;
}
.box p {
  margin-top: 15px;
}
.box img {
  width: 100%;
  height: 40vh;
}
.container h1 {
  margin-top: 50px;
  margin-left: 40px;
  position: absolute;
  color: #f5f7f9;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 114%;
  line-height: 55px;
}
/* .imgBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  object-fit: cover;
}
.imgBox img {
  width: 100%;
} */
.box1 {
  object-fit: cover;
  width: 418px;
  height: 355px;
  box-sizing: border-box;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  /* background: url("../../assets/dd.png"); */
}

.box2 {
  width: 418px;
  height: 355px;
  box-sizing: border-box;
  display: inline-block;
  object-fit: cover;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* background: url("../../assets/3th.png"); */
}
.box3 {
  object-fit: contain;
  width: 418px;
  height: 355px;
  background-size: cover;
  box-sizing: border-box;
  display: inline-block;
  background-repeat: no-repeat;
  /* background: url("../../assets/4th.png"); */
}
.email {
  width: 100%;
  background: #f5f7f9;
  display: flex;
  justify-content: center;
}
.Con {
  display: flex;
  height: 185px;
  width: 978px;
  align-items: center;
  border-bottom: 1px solid #d9dde1;
}
.mail {
  width: 448px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 114%;
}

.inputt {
  width: 406px;
  height: 55px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 114%;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1920px) {
  .main-two {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .main-two img {
    width: 45%;
  }
  .container {
    width: 1078px;
  }
  .contain {
    width: 1078px;
  }
  .contain h1 {
    width: 1078px;
  }
  .Card {
    width: 1078px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1070px) {
  .main-two {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 1070px) and (max-width: 1440px) {
  .main-two img {
    width: 50%;
  }
}
@media screen and (max-width: 770px) {
  .logo {
    margin-left: 5px;
  }
  /* .sticky {
    display: none;
  } */
  .subs {
    margin-left: 0px;
  }
  .subheader {
    width: 100%;
  }
  .subheader h1 {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 24px;
    margin-left: 50px;
  }
  .option {
    margin-left: 50px;
  }
  .option h3 {
    font-size: 14px;
  }
  .main-two {
    display: flex;
    flex-direction: column;
  }
  .cards {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .big {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .contain h1 {
    text-align: center;
    width: 100%;
  }
  .Con {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .mail {
    width: 100%;
  }
  .inputt {
    width: 100%;
  }
}
