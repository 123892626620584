.sticky {
  height: 10vh;
  display: flex !important;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #d9dde1;
  z-index: 1;
}
.subheader {
  height: 13vh;
}
.subs {
  margin-left: 200px;
  display: flex;
  align-items: center;
}
.subs .option {
  margin-left: 25px;
  align-items: center;
}
.subs h1 {
  font-size: 16px;
}
.subs h3 {
  color: #151515;
  font-size: 16px;
}
.logo {
  cursor: pointer;
  margin-left: 46px;
  margin-top: 10px;
}
.mership {
  height: 3rem;
  border-radius: 3px;
}
.subheader h1 {
  margin-left: 255px;
  margin-top: 20px;
  margin-bottom: 13px;
  font-family: "General Sans";
  font-style: "Semibold";
  font-weight: 500;
  font-size: 48px;
  line-height: 114%;
  line-height: 48px;
  color: #0d0d0d;
}
.subheader h3 {
  font-family: "Inter";
  font-style: "medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  line-height: 14px;
  color: #0d0d0d;
}
.option {
  height: 70px;
  cursor: pointer;
  display: flex;
  gap: 40px;
  margin-left: 255px;
  font-family: "Inter";
  font-style: "medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 114%;
  line-height: 18px;
}
.option a {
  text-decoration: none;
  color: #a0a3a7;
}
.main-two {
  /* height: 50vh; */
  display: flex;
  justify-content: center;
}

.mid-two {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mid-two-container {
  display: flex;
  width: 978px;
  gap: 20px;
  /* flex-direction: column; */
}
.mid-two img {
  gap: 10px;
  height: 50vh;
}
.mid-two h1 {
  margin-top: 120px;
  width: 978px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 114%;
  margin-bottom: 28px;
  color: #0d0d0d;
}
.vehicles {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
}
.contain {
  width: 978px;
  display: flex;
  /* position: relative; */
  /* color: white; */
  flex-direction: column;
  align-items: center;

  /* color: white; */
}
.contain h1 {
  width: 978px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 114%;
  margin-bottom: 28px;
  color: #0d0d0d;
}
.contain p {
  max-width: 978px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 138%;
  margin-bottom: 28px;
  color: #151515;
}

.big {
  display: flex;
  gap: 20px;
}
.box {
  display: flex;
  flex-direction: column;

  width: 50%;
}
.box p {
  margin-top: 15px;
}
.box img {
  width: 100%;
  height: 40vh;
}

/* .imgBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  object-fit: cover;
}
.imgBox img {
  width: 100%;
} */
.box1 {
  object-fit: cover;
  width: 418px;
  height: 355px;
  box-sizing: border-box;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  /* background: url("../../assets/dd.png"); */
}

.box2 {
  width: 418px;
  height: 355px;
  box-sizing: border-box;
  display: inline-block;
  object-fit: cover;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* background: url("../../assets/3th.png"); */
}
.box3 {
  object-fit: contain;
  width: 418px;
  height: 355px;
  background-size: cover;
  box-sizing: border-box;
  display: inline-block;
  background-repeat: no-repeat;
  /* background: url("../../assets/4th.png"); */
}
.safety {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.container {
  width: 978px;
  gap: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.safety-img img {
  width: 100%;
  height: 50vh;
}
.safety-text {
  height: 60vh;
  background-color: rgba(245, 247, 249, 1);
}
.safety h1 {
  margin-top: 50px;
  margin-left: 50px;
}
.safety ul {
  margin-left: 50px;
  list-style: none;
}
.safety li {
  font-size: 14px;
  font-weight: 400;
  margin-top: 30px;
}
.carousel {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.inside {
  width: 978px;
}
.drive {
  width: 100%;
  object-fit: contain;
}
.drive img {
  object-fit: contain;
  height: 29vh;
  background: linear-gradient(
    90deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.827812) 30.73%,
    rgba(255, 255, 255, 0.13) 100%
  );
}
.email {
  margin-top: 120px;
  width: 100%;
  background: #f5f7f9;
  display: flex;
  justify-content: center;
}
.Con {
  display: flex;
  height: 185px;
  width: 978px;
  align-items: center;
  border-bottom: 1px solid #d9dde1;
}
.mail {
  width: 448px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 114%;
}

.inputt {
  width: 406px;
  height: 55px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 114%;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1920px) {
  .subs h1 {
    font-size: 21px;
  }
  .option h3 {
    font-size: 21px;
  }
  .subheader {
    margin-left: 135px;
  }
  .subheader h3 {
    font-size: 24px;
  }
  .container {
    width: 1078px;
  }
  .contain {
    width: 1078px;
  }
  .mid-two-container {
    width: 1078px;
  }
  .mid-two h1 {
    width: 1078px;
  }
  .contain h1 {
    width: 1078px;
  }
  .Card {
    width: 1078px;
  }
}
@media screen and (min-width: 770px) and (max-width: 1070px) {
  .main-two img {
    width: 50%;
  }
}
@media screen and (min-width: 1070px) and (max-width: 1440px) {
}
@media screen and (max-width: 770px) {
  .logo {
    margin-left: 5px;
  }
  /* .sticky {
    display: none;
  } */
  .subs {
    margin-left: 0px;
  }
  .subheader {
    width: 100%;
  }
  .subheader h1 {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 24px;
    margin-left: 50px;
  }
  .option {
    margin-left: 50px;
  }
  .option h3 {
    font-size: 14px;
  }
  .main-two {
    display: flex;
    flex-direction: column;
  }
  .big {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .contain h1 {
    text-align: center;
    width: 100%;
  }
  .mid-two-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mid-two h1 {
    text-align: center;
    width: 100%;
  }
  .mid-two img {
    display: flex;
    align-items: center;
    height: 40vh;
  }
  .inside {
    width: 100%;
  }
  .Con {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .mail {
    width: 100%;
  }
  .inputt {
    width: 100%;
  }
}
