@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400&display=swap");

* {
  font-family: "Open Sans, 'Public Sans'";
}
.sticky {
  height: 10vh;
  display: flex !important;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #d9dde1;
  z-index: 1;
}
.subheader {
  height: 13vh;
}
.subs {
  margin-left: 200px;
  display: flex;
  align-items: center;
}
.subs .option {
  margin-left: 25px;
  align-items: center;
}
.subs h1 {
  font-size: 16px;
}
.subs h3 {
  color: #151515;
  font-size: 16px;
}
.logo {
  cursor: pointer;
  margin-left: 46px;
  margin-top: 10px;
}
.mership {
  height: 3rem;
  border-radius: 3px;
}
.subheader h1 {
  margin-left: 255px;
  margin-top: 15px;
  margin-bottom: 13px;
  font-family: "General Sans";
  font-style: "Semibold";
  font-weight: 500;
  font-size: 38px;
  line-height: 114%;
  line-height: 48px;
  color: #0d0d0d;
}
.option {
  height: 70px;
  cursor: pointer;
  display: flex;
  gap: 40px;
  margin-left: 255px;
  font-family: "Inter";
  font-style: "medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 114%;
  line-height: 18px;
}
.option h3 {
  font-size: 16px;
}
.option a {
  text-decoration: none;
  color: #151515;
}
.hero {
  width: 100%;
  height: 100%;
}
.hero img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  overflow: hidden;
}
.hero1 img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
}
.hero2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.co {
  width: 978px;
}
.hero2 img {
  object-fit: cover;
}

.hero2 p {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 114%;
}
.zurag1 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.zurag1 .arrow {
  position: absolute;
  bottom: 10px;
  right: 15px;
  font-size: 18px;
  width: 42px;
  height: 42px;
  cursor: pointer;
}
.zurag2 {
  position: relative;
}
.zurag2 .arrow {
  position: absolute;
  bottom: 100px;
  right: 17px;
  font-size: 18px;
  width: 42px;
  height: 42px;
  cursor: pointer;
}
.zurag3 {
  position: relative;
}
.zurag3 .arrow {
  position: absolute;
  bottom: 12px;
  right: 15px;
  font-size: 18px;
  width: 42px;
  height: 42px;
  cursor: pointer;
}
.zurag1 img {
  width: 70%;
  height: 40vh;
  margin-top: 15px;
}
.zurag1 p {
  margin-right: 665px;
  margin-top: 100px;
}
.zrg {
  display: flex;
  gap: 200px;
}
.zurag2 img {
  width: 100%;
  height: 40vh;
  margin-top: 15px;
}
.zurag2 p {
  margin-top: 55px;
}
.zurag3 img {
  margin-top: 15px;
  width: 100%;
  height: 50vh;
  /* margin-left: 200px; */
}
.zurag3 p {
  margin-top: 10px;
}

/* .hero2 img {
  width: 350px;
  height: 30vh;
  object-fit: cover;
  overflow: hidden;
} */
.text {
  max-width: 70%;
  margin-left: 100px;
}
.lorem {
  width: 70%;
  margin-left: 300px;
  margin-bottom: 100px;
}

.list img {
  width: 10px;
}

.main-image {
  margin-top: 180px;
  width: 100%;
  height: 80vh;
}

.main-image img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  background: no-repeat;
}
.content2 {
  display: flex;
  max-width: 100%;
  height: 200px;
  background-color: black;
  color: white;
  flex-direction: column;
  justify-content: center;
}
.conn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
.show {
  color: #a0a3a7;
}
.show .first-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 114%;
  color: #f5f7f9;
}
.show .sec-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 114%;
  margin-left: 10px;
}
.h1 {
  color: #f5f7f9;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 114%;
}
.p1 {
  margin-top: 34px;
  color: #a0a3a7;
}
.image {
  object-fit: cover;
}
.haha {
  margin: 0 auto;
  height: 100vh;
}
.main {
  height: 110vh;
}
.hero-image {
  display: flex;
  background: url(../../../assets/back.png);
  object-fit: cover;
  height: 60vh;
  flex-direction: column;
}

.hero-text {
  margin-top: 80px;
  text-align: center;
  color: white;
}
.hero-text h1 {
  width: 50%;
  margin-top: 50px;
  display: inline-block;
}
.hero-text p {
  margin-top: 30px;
  width: 60%;
  display: inline-flex;
  justify-content: start;
}
.list {
  margin-top: 50px;
  margin-left: 220px;
  gap: 90px;
  width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
}
.list img {
  border-top: 5px solid blue;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ext {
  margin-top: 180px;
  display: flex;
  width: 100%;
  height: 90vh;
  /* flex-direction: column; */
  justify-content: center;
}
.CoO {
  width: 978px;
}
.ext img {
  height: 70%;
  width: 70%;
  margin-left: 100px;
  object-fit: contain;
}
.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 119%;
  color: #a0a3a7;
}
.colors {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.car-name {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 119%;
  color: #0d0d0d;
}
.color {
  height: 26px;
  width: 26px;
  margin-left: 26px;
  border-radius: 18px;
  cursor: pointer;
}
.select1 {
  width: 26px;
  height: 26px;
  background: #dfdfdf;
  border-radius: 100%;
  box-shadow: inset 0px 0px 0px 2px white;
  border: 1px solid #dfdfdf;
}
.select2 {
  width: 26px;
  height: 26px;
  background: #8ed1fc;
  border-radius: 100%;
  box-shadow: inset 0px 0px 0px 2px white;
  border: 1px solid #8ed1fc;
}
.select3 {
  width: 26px;
  height: 26px;
  background: #7c7c7c;
  border-radius: 100%;
  box-shadow: inset 0px 0px 0px 2px white;
  border: 1px solid #7c7c7c;
}
.select4 {
  width: 26px;
  height: 26px;
  background: #63803a;
  border-radius: 100%;
  box-shadow: inset 0px 0px 0px 2px white;
  border: 1px solid #63803a;
}
.select5 {
  width: 26px;
  height: 26px;
  background: #dfdfdf;
  border-radius: 100%;
  box-shadow: inset 0px 0px 0px 2px white;
  border: 1px solid #0d4671;
}
.select6 {
  width: 26px;
  height: 26px;
  background: #dfdfdf;
  border-radius: 100%;
  box-shadow: inset 0px 0px 0px 2px white;
  border: 1px solid black;
}
/* .video {
  max-width: 1320px;
} */
.vid {
  /* width: 1078px; */
  display: flex;
  justify-content: center;
  gap: 21px;
}

.vid video {
  width: 345px;
  height: 221px;
  object-fit: contain;
  align-items: center;
}
.vyh {
  width: 100%;
}

.tom {
  height: 100vh;
  width: 100%;
  opacity: 0.98;
  background-color: #444444;
  /* display: none; */
  position: fixed;
  z-index: 1;
  margin-top: -128px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}
.jijig {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  background-color: #fff;
  opacity: 1;
  padding: 10px;
  width: 500px;
  height: 500px;
}
.X {
  margin-top: 105px;
  margin-left: 500px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 30px;
  padding: 10px;
  position: absolute;
  border: solid 2px #000;
  width: 35px;
  height: 38px;
}
.wrapper {
  /* margin-left: 80px; */
  display: grid;
  grid-template-columns: repeat(4, 110px);
  grid-auto-rows: 100px;
  align-items: center;
  text-align: center;
}
.wrapper img {
  width: 50px;
}
.title {
  /* margin-top: 300px; */
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 114%;
  margin-bottom: 80px;
}
.title .first {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 114%;
  color: #0d0d0d;
}
.title .second {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 114%;
  color: rgba(160, 163, 167, 1);
}
.specs {
  margin-top: 222px;
  display: flex;
  width: 100%;
  background: #f5f7f9;
  height: 120vh;
  justify-content: center;
  align-items: center;
}
.ContaineR {
  width: 978px;
}
.spec-img {
  /* max-width: 100%; */
  float: left;
}
.spec-img img {
  width: 500px;
  height: 500px;
}
.spec-item {
  gap: 40px;
  display: flex;
  width: 40%;
  float: right;
}
.spec-item h1 {
  margin-top: 36px;
  font-weight: 600;
  font-size: 14px;
}
.spec-item p {
  margin-top: 5px;
  font-size: 9px;
}
.cover {
  width: 100%;
  height: 90vh;
  /* background: url(../../../../assets/back.png); */
}
.background {
  width: 1550px;
  display: flex;
  justify-content: center;
  height: 90vh;
  background: url(../../../assets/back.png);
}
.background h1 {
  margin-top: 50px;
  color: white;
}
/* .background img {
    display: flex;
    width: 350px;
  } */
.gallery {
  display: flex;
  gap: 20px;
  margin-top: 150px;
}
.g1 {
  width: 643px;
  height: 670px;
  object-fit: cover;
  margin-right: 9px;
}
.g2 {
  width: 351px;
  height: 670px;
  object-fit: cover;
  margin-right: 9px;
}
.g3 {
  height: 470px;
  width: 97px;
  object-fit: cover;
  margin-right: 9px;
}
.text-content {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 180px;
}
.coo {
  width: 978px;
  display: flex;
  gap: 40px;
  align-items: center;
}
.t1 {
  width: 404px;
  /* margin-top: 180px; */
  margin-bottom: 180px;
}
.t1 .f1 {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 114%;
  color: #0d0d0d;
}
.t1 .f2 {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 114%;
  color: rgba(160, 163, 167, 1);
}
.t2 {
  /* margin-top: 238px; */
  width: 295px;
  margin-bottom: 196px;
}
.t3 {
  /* margin-top: 238px; */
  width: 295px;
  margin-bottom: 196px;
}
.t1 h1 {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 114%;
}
.t2 h2 {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 138%;
  color: #151515;
}
.t2 p {
  margin-top: 59px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 138%;
}
.t3 h2 {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 138%;
}
.t3 p {
  margin-top: 59px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 138%;
}

.grid {
  display: flex;
  justify-content: space-between;
}
.part1 {
  display: flex;
  align-items: flex-end;
}
.part1 .first-num {
  margin-top: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 114%;
  color: #0d0d0d;
}
.part1 .second-value {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 114%;
  color: #a0a3a7;
  margin-left: 15px;
  margin-bottom: 8px;
}

.grid p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 114%;
  color: #a0a3a7;
}
.order {
  width: 100%;
  height: 110vh;
  background: #0d0d0d;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.order-inside {
  width: 978px;
  display: flex;
}
.order-now {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.order-now h1 {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 114%;
  color: #f5f7f9;
}
.order-img {
  width: 100%;
}
.order-inside img {
  /* height: 612px; */
  width: 100%;
  object-fit: cover;
}
.order-inside h1 {
  color: white;
}
.btn {
  color: white;
  display: inline-flex;
  gap: 15px;
  margin-top: 24px;
  margin-right: 112px;
}
.btn1 {
  width: 148px;
  height: 34px;
  color: white;
  background-color: black;
  border: 1px solid #f5f7f9;
  font-family: "Inter";
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
}
.btn2 {
  background-color: #0d0d0d;
  cursor: pointer;
  width: 148px;
  height: 34px;
  color: white;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 114%;
}
.voyah-free {
  width: 100%;
  display: flex;
  justify-content: center;
  /* width: 1078px; */
}
.Container {
  width: 978px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.Container h1 {
  width: 978px;
  margin-top: 161px;
  margin-bottom: 28px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 114%;
  color: #0d0d0d;
}
.free-text {
  width: 978px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 138%;
  color: #151515;
}
.cards {
  width: 100%;
  display: flex;
}
.Card {
  width: 978px;
  background: #f5f7f9;
  width: 352px;
  height: 458px;
  border-color: rgb(95, 95, 95);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.5s all ease-in-out;
  transform: scale(1);
  overflow: hidden;
  margin: 11px;
  margin-top: 28px;
}

.card-image img {
  width: 352px;
  height: 300px;
  object-fit: cover;
}

/* .Card:hover {
  cursor: pointer;
  transform: scale(0.9);
} */
.text1 {
  margin-top: 18px;
  margin-left: 17px;
  margin-right: 16px;
  font-family: Verdana, sans-serif;
  width: 300px;
  height: 158px;
  text-align: start;
}
.text1 h3 {
  font-style: normal;
  line-height: 138%;
  color: #151515;
}
.pi1 {
  margin-top: 8px;
  width: 300px;
  /* text-align: justify; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 138%;
  color: #a0a3a7;
}

.vehicles {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
}
.contain {
  width: 978px;
  display: flex;

  flex-direction: column;
  justify-content: center;
}
.contain h1 {
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 114%;
  margin-bottom: 28px;
  color: #0d0d0d;
}
.contain p {
  max-width: 978px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 138%;
  margin-bottom: 28px;
  color: #151515;
}

.container {
  width: 978px;
  display: flex;
  gap: 21px;
  flex-direction: row;
  flex-wrap: wrap;
}
.box {
  width: 48%;
  height: 470px;
  object-fit: cover;
  box-sizing: border-box;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background: url("../../../assets/voyy.png");
}
.container h1 {
  margin-top: 50px;
  margin-left: 40px;
  position: absolute;
  color: #f5f7f9;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 114%;
  line-height: 55px;
}
.imgBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  background: linear-gradient(
    rgba(13, 13, 13, 0.78),
    rgba(13, 13, 13, 0.13),
    rgba(13, 13, 13, 0.6)
  );
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.imgBox .arrow {
  width: 48px;
  height: 48px;
}
.box1 {
  object-fit: cover;
  width: 48%;
  height: 470px;
  box-sizing: border-box;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background: url("../../../assets/dd.png");
}

.box2 {
  width: 48%;
  height: 470px;
  box-sizing: border-box;
  display: inline-block;
  object-fit: cover;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background: url("../../../assets/3th.png");
}
.box3 {
  object-fit: contain;
  width: 48%;
  height: 470px;
  background-size: cover;
  box-sizing: border-box;
  display: inline-block;
  background-repeat: no-repeat;
  background: url("../../../assets/4th.png");
}
.Txt {
  /* position: absolute; */
  top: 194px;
  left: 50px;
  color: white;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 114%;
}
.Btn {
  display: flex;
  justify-content: center;
  margin-top: 72px;
  margin-bottom: 80px;
}
.Btn button {
  border: 1px solid #151515;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  width: 148px;
  height: 34px;
}
.email {
  width: 100%;
  background: #f5f7f9;
  display: flex;
  justify-content: center;
}
.Con {
  display: flex;
  height: 185px;
  width: 978px;
  align-items: center;
  border-bottom: 1px solid #d9dde1;
}
.mail {
  width: 448px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 114%;
}

.inputt {
  padding: 20px;
  width: 406px;
  height: 55px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 114%;
  display: flex;
  align-items: center;
}
.wrap {
  display: flex;
  flex-direction: column;
  background: #f5f7f9;
  max-width: 100%;
  height: 913px;
  padding: 5rem;
  margin-top: 102px;
  margin-bottom: 102px;
  justify-content: center;
  align-items: center;
}
.wrap h1 {
  display: flex;
  justify-content: center;
  /* font-style: normal;
	font-weight: 500;
	line-height: 114%; */
  font-size: 39px;
}

.containerint {
  width: 978px;
  justify-content: center;
  grid-gap: 1.2rem;
  justify-content: center;
  display: flex;
  --num-cols: 5;
  margin-top: 16px;
  /* margin-block: 150px; */
}

.featured .featured-text {
  text-align: center;
  margin: 1rem 0;
}
.pic {
  width: 306px;
  height: 395px;
  object-fit: cover;
}
.pic2 {
  width: 435px;
  height: 598px;
  object-fit: cover;
}

.pic3 {
  width: 295px;
  height: 395px;
  object-fit: cover;
}
.titleint {
  box-sizing: border-box;
  padding-top: 38px;
  color: #a0a3a7;
  font-size: 16px;
}
.desc {
  margin-right: 760px;
}
.bigIcon {
  color: #a0a3a7;
  padding-top: 0%;
  margin-top: 265px;
  margin-left: 50px;
}
/* exterior */
.carousel-container-with-scrollbar {
  padding-left: 325px;
  padding-bottom: 222px;
}
.wrap h1 {
  display: flex;
  justify-content: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 114%;
}
.increase-size {
  width: 741px;
  height: 457px;
  margin-block: 20px;
  padding: 60px 0;
  margin-block: 33px;
  grid-gap: 1.2rem;
}
.size {
  width: 741px;
  height: 457px;
  object-fit: cover;
  grid-gap: 1.2rem;
}
.text {
  box-sizing: border-box;
  padding-top: 38px;
  color: #a0a3a7;
  font-size: 16px;
  font-family: "Open Sans";
}
/* .container {
  padding: 0;
  width: 1500px;
  justify-content: center;
} */

.wrapper {
  max-width: 1500px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}
.wrapper h1 {
  margin-left: 325px;
  font-size: 42px;
}
.exterior h1 {
  margin-left: 31px;
}
.react-multiple-carousel__arrow {
  margin-top: 123px;
}
.voyah-video {
  width: 100%;
  margin-bottom: 130px;
}
@media screen and (min-width: 1920px) {
  .subs h1 {
    font-size: 21px;
  }
  .option h3 {
    font-size: 21px;
  }
  .subheader {
    margin-left: 135px;
  }
  .subheader h3 {
    font-size: 24px;
  }
  .hero img {
    width: 100%;
    object-fit: cover;
    height: 100vh;
  }
  .free_hero2__wKFvQ {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-image img {
    width: 100%;
    object-fit: cover;
    background: no-repeat;
  }
  .hero2 {
    width: 100%;
  }
  .co {
    width: 1078px;
  }
  .zurag1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .zurag1 .arrow {
    position: absolute;
    bottom: 5px;
    right: 170px;
    font-size: 18px;
    width: 42px;
    height: 42px;
    cursor: pointer;
  }
  .zurag1 .promo img {
    width: 100%;
  }
  .zurag2 {
    position: relative;
  }
  .zurag2 .arrow {
    position: absolute;
    bottom: 75px;
    right: 10px;
    font-size: 18px;
    width: 42px;
    height: 42px;
    cursor: pointer;
  }
  .zurag1 p {
    font-size: 21px;
    margin-right: 665px;
    margin-top: 100px;
  }
  .zrg {
    font-size: 21px;
  }
  .ext {
  }
  .CoO {
    width: 1078px;
  }
  .ext img {
    margin-left: 0;
    width: 100%;
  }
  /* .CoO {
		margin-left: 100px;
	} */
  /* .vid {
    display: flex;
    justify-content: center;
    gap: 21px;
  }

  .vid video {
    width: 345px;
    height: 221px;
    object-fit: contain;
    align-items: center;
  } */

  .containeR {
    margin-top: 127px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    height: 700px;
    display: flex;
    margin-bottom: 133px;
  }
  .specs {
    margin-top: 222px;
    display: flex;
    width: 100%;
    background: #f5f7f9;
    height: 905px;
    justify-content: center;
    align-items: center;
  }
  .title {
    /* margin-top: 300px; */

    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 114%;
    /* margin-bottom: 139px; */
  }
  .title .first {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 114%;
    color: #0d0d0d;
    /* margin-top: 85px; */
  }
  .spec-img {
    float: left;
    width: 560px;
    height: 500px;
    /* margin-left: 325px;
		margin-right: 271px; */
  }
  .title .second {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 42px;
    line-height: 114%;
    color: rgba(160, 163, 167, 1);
  }
  .order {
    width: 100%;
    height: 100vh;
    background: #0d0d0d;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  /* .order-img {
    min-width: 978px;
  } */
  .order-inside {
    width: 1078px;
  }
  .order-img {
    min-width: 1078px;
  }
  .Container {
    width: 1078px;
  }
  .Container h1 {
    font-size: 46px;
    width: 1078px;
  }
  .Container p {
    font-size: 18px;
  }
  .contain {
    width: 1078px;
  }
  .contain h1 {
    font-size: 46px;
  }
  .contain p {
    align-items: center;
    font-size: 18px;
  }
  .container {
    width: 1078px;
    align-items: center;
    justify-content: center;
  }

  .Con {
    width: 1078px;
  }
  .mail {
    width: 100%;
    font-size: 26px;
  }
  .inputt {
    font-size: 21px;
  }
}
@media screen and (min-width: 1070px) and (max-width: 1440px) {
  .box {
    width: 38%;
  }
  .box1 {
    width: 38%;
  }
  .box2 {
    width: 38%;
  }
  .box3 {
    width: 38%;
  }
}
@media screen and (min-width: 770px) and (max-width: 1070px) {
  .pic {
    width: 100%;
    height: 295px;
    object-fit: cover;
  }
  .pic2 {
    width: 195px;
    height: 498px;
    object-fit: cover;
  }

  .pic3 {
    width: 195px;
    height: 295px;
    object-fit: cover;
  }
}
@media screen and (min-width: 770px) and (max-width: 1440px) {
  .co {
    width: 100%;
  }
  .ContaineR {
    width: 100%;
  }
  .t3 {
    width: 30%;
  }
  .t3 h2 {
    width: 100%;
  }
  .t3 p {
    width: 100%;
  }
  .Container {
    width: 100%;
  }
  .Container h1 {
    width: 100%;
  }
  .free-text {
    width: 100%;
  }
  .pi1 {
    width: 90%;
  }
  .coo {
    width: 100%;
  }
  .contain {
    width: 100%;
  }
  .container {
    width: 100%;
  }
  .Con {
    width: 100%;
  }
  .containerint {
    width: 100%;
  }
  .desc {
    margin-right: 200px;
  }
}
@media screen and (max-width: 770px) {
  .logo {
    margin-left: 5px;
  }
  /* .sticky {
    display: none;
  } */
  .subs {
    margin-left: 0px;
  }
  .subheader {
    width: 100%;
  }
  .subheader h1 {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 24px;
    margin-left: 50px;
  }
  .option {
    margin-left: 50px;
  }
  .option h3 {
    font-size: 14px;
  }
  /* .subheader_fixed {
    display: none;
  } */
  .hero {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .hero img {
    width: 100%;
    height: 50%;
    object-fit: cover;
    overflow: hidden;
  }
  .hero2 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .co {
    width: 80%;
  }
  .zrg {
    gap: 50px;
  }
  .zurag1 {
    margin: none;
  }
  .zurag1 p {
    margin-right: 232px;
  }
  .zurag1 img {
    width: 100%;
    height: 100%;
  }
  .zurag1 .arrow {
    right: 20px;
    bottom: 10px;
    width: 32px;
    height: 32px;
  }
  .zurag2 img {
    width: 100%;
    height: 100%;
  }
  .zurag2 .arrow {
    right: 20px;
    bottom: 10px;
    width: 32px;
    height: 32px;
  }
  .zurag3 img {
    width: 100%;
    height: 100%;
  }
  .zurag3 .arrow {
    right: 20px;
    bottom: 10px;
    width: 32px;
    height: 32px;
  }
  .zrg {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-image {
    height: 24%;
    width: 100%;
    margin-top: 65px;
  }
  .main-image img {
    width: 100%;
    height: 24%;
    object-fit: cover;
  }
  .content2 {
    height: 8vh;
  }
  .show .first-text {
    font-size: 10px;
  }
  .show .sec-text {
    font-size: 10px;
  }
  .p1 {
    margin: 0;
    font-size: 10px;
  }

  .colors {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 10px;
  }

  .ext {
    height: 60vh;
    margin-top: 60px;
  }
  .ext img {
    margin-left: 0;
    width: 100%;
  }
  .name {
    font-size: 16px;
  }
  .color {
    width: 20px;
    height: 20px;
    margin-left: 0;
  }

  .car-name {
    margin-top: 30px;
    font-size: 16px;
  }
  .CoO {
    width: 80%;
    /* margin-left: 100px; */
  }
  .vid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .vid video {
    width: 30%;
    height: 30%;
  }
  .specs {
    display: flex;
  }
  .cOntainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ContaineR {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .spec-img {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .title {
    margin: 0;
  }
  .cOntainer {
    margin-left: 0;
  }
  .spec-img img {
    width: 70%;
    object-fit: contain;
  }
  .coo {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .t1 {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .t2 {
    margin-bottom: 60px;
  }
  .t2 p {
    margin-top: 20px;
  }
  .t3 p {
    margin-top: 20px;
  }
  .order {
    /* display: block; */
  }
  .order-inside {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .order-img {
    margin-top: 40px;
    width: 100%;
  }
  .order-inside img {
    width: 100%;
    object-fit: cover;
  }
  .btn {
    margin-right: 0;
  }
  .voyah-free {
    display: flex;
  }
  .Container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Container h1 {
    width: 80%;
    margin-top: 60px;
  }
  .free-text {
    width: 80%;
  }
  .box {
    background-repeat: no-repeat;
    width: 100%;
  }
  .box1 {
    background-repeat: no-repeat;
    width: 100%;
  }
  .box2 {
    background-repeat: no-repeat;
    width: 100%;
  }
  .box3 {
    background-repeat: no-repeat;
    width: 100%;
  }
  .contain {
    width: 100%;
    align-items: center;
  }
  .contain h1 {
    width: 80%;
  }
  .contain p {
    width: 80%;
  }
  .container {
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: center;
  }
  .Con {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .mail {
    width: 100%;
  }
  .inputt {
    width: 100%;
  }
  .wrap {
    display: flex;
    background: #f5f7f9;
    max-width: 100%;
    height: 913px;
    padding: 5rem;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
  }
  .wrap h1 {
    display: flex;
    justify-content: center;
    margin-right: 100px;
    /* font-style: normal;
		font-weight: 500;
		line-height: 114%; */
    font-size: 39px;
  }

  .containerint {
    width: 100%;
    justify-content: center;
    grid-gap: 1.2rem;
    justify-content: center;
    display: flex;
    --num-cols: 5;
    /* margin-top: 16px;
		margin-block: 150px; */
    margin-left: 350px;
    margin-right: 290px;
  }

  .featured .featured-text {
    text-align: center;
    margin: 1rem 0;
  }
  .pic {
    width: 100%;
    height: 95px;
    object-fit: cover;
  }
  .pic2 {
    width: 95px;
    height: 198px;
    object-fit: cover;
  }

  .pic3 {
    width: 95px;
    height: 95px;
    object-fit: cover;
  }
  .titleint {
    box-sizing: border-box;
    padding-top: 38px;
    color: #a0a3a7;
    font-size: 11px;
  }
  .desc {
    margin-top: 47px;
    font-size: 11px;
  }
  .bigIcon {
    color: #a0a3a7;
    padding-top: 0%;
    margin-top: 265px;
    margin-left: 89px;
  }
  .carousel-container-with-scrollbar {
    padding-left: 5px;
    padding-bottom: 222px;
  }
  .wrap h1 {
    display: flex;
    justify-content: center;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 114%;
  }
  .increase-size {
    width: 741px;
    height: 457px;
    margin-block: 20px;
    padding: 60px 0;
    margin-block: 33px;
    grid-gap: 1.2rem;
  }
  .size {
    width: 741px;
    height: 457px;
    object-fit: cover;
    grid-gap: 1.2rem;
  }
  .text {
    box-sizing: border-box;
    padding-top: 38px;
    color: #a0a3a7;
    font-size: 14px;
    font-family: "Open Sans";
  }

  .wrapper {
    max-width: 1500px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
  }
  .wrapper h1 {
    margin-left: 5px;
    font-size: 42px;
  }
  .exterior h1 {
    margin-left: 31px;
  }
  .react-multiple-carousel__arrow {
    margin-top: 123px;
  }
}
