@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open sans";
}
nav {
  opacity: 2;
  z-index: 99;
  width: 100%;
  background: #f5f7f9;
}
nav .wrapper {
  opacity: 2;
  position: relative;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .logo a {
  color: black;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.logo {
  cursor: pointer;
  margin-left: 46px;
  margin-top: 10px;
}
.mership {
  height: 3rem;
  border-radius: 3px;
}
.models {
  color: black;
  display: flex;
  align-items: center;
}
.asd {
  color: gray;
}
.brands {
  cursor: pointer;
  margin-right: 50px;
  display: flex;
  gap: 30px;
  font-family: "General Sans";
  font-style: medium;
  font-weight: 500;
  font-size: 16px;
  line-height: 114%;
  height: 9vh;
}
.search {
  width: 16px;
  cursor: pointer;
}
.wrapper .nav-links {
  height: 100%;
  display: inline-flex;
}
.nav-links li {
  list-style: none;
}
.nav-links li a {
  color: black;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.models:hover .mega-box {
  transition: all 0.3s ease;
  top: 9vh;
  opacity: 1;
  visibility: visible;
}

.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0 0 0 / 80%);
  /* padding: 0 30px; */
  /* top: 85px; */
  opacity: 0;
  visibility: hidden;
}
.mega-box .content {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.6);
  background: #fff;
  display: flex;
  /* height: 100%; */
  top: 85px;
  justify-content: center;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  height: 80vh;
}
.mega-box .content .row {
  line-height: 45px;
  display: flex;
  width: 978px;
  justify-content: center;
  margin: 60px;
}
.closemenu {
  display: none;
}
.mega-box .content .row img:hover {
  transform: scale(1.05);
}
.content .row img {
  width: 50vh;
  height: 50vh;
  background-repeat: no-repeat;
  object-fit: cover;
}
.content .row header {
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}
.row .arrow {
  left: 700px;
  background-color: none;
  position: absolute;
  bottom: 235px;
  width: 42px !important;
  height: 42px !important;
  cursor: pointer;
}
.row .arrow1 {
  position: absolute;
  bottom: 235px;
  right: 375px;
  width: 42px !important;
  height: 42px !important;
  cursor: pointer;
}
.content .row h2 {
  font-family: "General Sans";
  font-style: medium;
  font-weight: 500;
  font-size: 38px;
  line-height: 55px;
  line-height: 114%;
  color: rgba(13, 13, 13, 0.78);
  margin-bottom: 26px;
}
.content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}
.row .mega-links li {
  padding: 0 20px;
}
.row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 17px;
  display: block;
}
.row .mega-links li a:hover {
  color: #f2f2f2;
}
#nav-toggle {
  position: absolute;
}
.icon-burger {
  display: none;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}
.icon-burger .line {
  cursor: pointer;
  width: 20px;
  height: 6px;
  background-color: yellow;
  margin: 5px;
  border-radius: 3px;
}

.btn {
  cursor: pointer;
  border: none;
  display: none;
  position: absolute;
  right: 10px;
  top: 7px;
  padding: 5px;
  color: #000;
  font-size: 18px;
}
@media screen and (max-width: 770px) {
  .mership {
    height: 2rem;
  }
  .brands {
    gap: 0;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .nav-links {
    display: block;
    /* position: absolute;
    top: 60px;
    left: 0; */
    /* flex-direction: column; */
    width: 100%;
    height: calc(20vh - 77px);
    background-color: white;
    /* border-top: 1px solid black; */
  }
  .brands .models {
    display: none;
    text-align: center;
    margin: 0;
  }
  .mega-box {
    display: none;
  }
  .models {
    display: block;
  }
  .btn {
    background-color: transparent;
    display: block;
  }
  .search {
    display: none;
  }
}
@media screen and (min-width: 770px) and (max-width: 1440px) {
  /* .mega-box .content {
    display: none;
  } */
  /* .brands {
    display: none;
  } */
  .row .arrow1 {
    display: none;
  }
  .row .arrow {
    display: none;
  }
}
@media screen and (min-width: 1920px) {
  .brands {
    font-size: 24px;
  }
  nav .wrapper {
    justify-content: space-around;
    gap: 400px;
  }
  .search {
    width: 26px;
  }
  .mership {
    height: 4rem;
    border-radius: 3px;
  }
  .row .arrow1 {
    bottom: 440px;
    right: 575px;
  }
  .row .arrow {
    bottom: 440px;
    left: 570px;
  }
}
