.carousel-container-with-scrollbar {
  padding-left: 325px;
  padding-bottom: 222px;
}
.wrap h1 {
  display: flex;
  justify-content: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 114%;
}
.increase-size {
  width: 741px;
  height: 457px;
  margin-block: 20px;
  padding: 60px 0;
  margin-block: 33px;
  grid-gap: 1.2rem;
}
.size {
  width: 741px;
  height: 457px;
  object-fit: cover;
  grid-gap: 1.2rem;
}
.text {
  box-sizing: border-box;
  padding-top: 38px;
  color: #a0a3a7;
  font-size: 16px;
  font-family: "Open Sans";
}
.container {
  padding: 0;
  width: 1500px;
  justify-content: center;
}

.wrapper {
  max-width: 1500px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}
.wrapper h1 {
  margin-left: 325px;
  font-size: 42px;
}
.exterior h1 {
  margin-left: 31px;
}
.react-multiple-carousel__arrow {
  margin-top: 123px;
}
@media screen and (max-width: 770px) {
  .carousel-container-with-scrollbar {
    padding-left: 5px;
    padding-bottom: 222px;
  }
  .wrap h1 {
    display: flex;
    justify-content: center;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 114%;
  }
  .increase-size {
    width: 741px;
    height: 457px;
    margin-block: 20px;
    padding: 60px 0;
    margin-block: 33px;
    grid-gap: 1.2rem;
  }
  .size {
    width: 741px;
    height: 457px;
    object-fit: cover;
    grid-gap: 1.2rem;
  }
  .text {
    box-sizing: border-box;
    padding-top: 38px;
    color: #a0a3a7;
    font-size: 14px;
    font-family: "Open Sans";
  }
  .container {
    padding: 0;
    width: 1500px;
    justify-content: center;
  }

  .wrapper {
    max-width: 1500px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
  }
  .wrapper h1 {
    margin-left: 5px;
    font-size: 42px;
  }
  .exterior h1 {
    margin-left: 31px;
  }
  .react-multiple-carousel__arrow {
    margin-top: 123px;
  }
}
