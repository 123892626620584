/* .main-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
}

.main-image img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
} */
.main {
  /* position: relative; */
  width: 100%;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  background: no-repeat;
  background: url(../../assets/voyah.png);
  background-size: 100%;
  object-fit: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  /* align-items: center; */
}

.img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.content {
  width: 500px;
  align-items: center;
  color: white;
  font-size: 20px;
}
.voyah {
  width: 200px;
}
