.wrap {
  display: flex;
  flex-direction: column;
  background: #f5f7f9;
  max-width: 100%;
  height: 913px;
  padding: 5rem;
  margin-top: 102px;
  margin-bottom: 102px;
  justify-content: center;
  align-items: center;
}
.wrap h1 {
  margin-right: 760px;
  display: flex;
  justify-content: center;
  /* font-style: normal;
	font-weight: 500;
	line-height: 114%; */
  font-size: 39px;
}

.container {
  width: 978px;
  justify-content: center;
  grid-gap: 1.2rem;
  justify-content: center;
  display: flex;
  --num-cols: 5;
  margin-top: 16px;
  /* margin-block: 150px; */
}

.pic {
  width: 306px;
  height: 395px;
  object-fit: cover;
}
.pic2 {
  width: 435px;
  height: 598px;
  object-fit: cover;
}

.pic3 {
  width: 295px;
  height: 395px;
  object-fit: cover;
}
.title {
  box-sizing: border-box;
  padding-top: 38px;
  color: #a0a3a7;
  font-size: 16px;
}
.desc {
  /* margin-top: 47px; */
}
.bigIcon {
  color: #a0a3a7;
  padding-top: 0%;
  margin-top: 265px;
  margin-left: 50px;
}
@media screen and (min-width: 1920px) {
}
@media screen and (min-width: 770px) and (max-width: 1070px) {
  .pic {
    width: 206px;
    height: 295px;
    object-fit: cover;
  }
  .pic2 {
    width: 335px;
    height: 498px;
    object-fit: cover;
  }

  .pic3 {
    width: 195px;
    height: 295px;
    object-fit: cover;
  }
}
@media screen and (min-width: 770px) and (max-width: 1440px) {
  .wrap h1 {
    margin-left: 200px;
  }
  .container {
    width: 100%;
  }
}
@media screen and (max-width: 770px) {
  .wrap {
    display: flex;
    background: #f5f7f9;
    max-width: 100%;
    height: 613px;
    padding: 5rem;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
  }
  .wrap h1 {
    margin-right: 100px;
    display: flex;
    justify-content: center;
    /* font-style: normal;
		font-weight: 500;
		line-height: 114%; */
    font-size: 39px;
  }

  .container {
    width: 100%;
  }
  .sub-container {
    width: 34%;
  }
  .featured .featured-text {
    text-align: center;
    margin: 1rem 0;
  }
  .pic {
    width: 100%;
    height: 95px;
    object-fit: cover;
  }
  .pic2 {
    width: 135px;
    height: 198px;
    object-fit: cover;
  }

  .pic3 {
    width: 95px;
    height: 95px;
    object-fit: cover;
  }
  .title {
    width: 100%;
    box-sizing: border-box;
    padding-top: 38px;
    color: #a0a3a7;
    font-size: 11px;
  }
  .desc {
    margin-top: 47px;
    font-size: 11px;
  }
  .bigIcon {
  }
}
