.cOntainer {
  /* display: flex; */
  flex-direction: column;
  margin-left: 100px;
}

.spec-item {
  gap: 40px;
  display: flex;
  /* width: 40%; */
  margin-left: 112px;
}
.spec-item h1 {
  margin-top: 26px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  line-height: 114%;
}
.spec-item p {
  margin-top: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 114%;
  color: #a0a3a7;
}
.row-one {
  float: left;
  margin-left: 112px;
}
.row-two {
  float: right;
}
.container {
  display: flex;
}
.tabs {
  display: flex;
  align-items: flex-start;
}
.one {
  margin-left: 100px;
}
.tabs button {
  margin-left: 112px;
  border: none;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 114%;
  border-bottom: solid 1px rgba(0, 0, 0, 0);
  background-color: transparent;
}
.tabs button:hover {
  border-bottom-color: black;
  text-shadow: 5px 5px 21px white;
}
@media screen and (min-width: 770px) and (max-width: 1070px) {
}
@media screen and (min-width: 770px) and (max-width: 1440px) {
  .cOntainer {
    margin-right: 0;
    width: 50%;
  }
  .spec-item {
    /* gap: 100px; */
    margin-left: -35px;
  }
  .row-one {
    margin-left: 50px;
  }
  .tabs {
    margin-top: 40px;
  }
  .tabs button {
    margin-left: 17px;
    margin-right: 1px;
  }
}
@media screen and (max-width: 770px) {
  .cOntainer {
    margin: 0;
  }
  .spec-item {
    justify-content: center;
    margin-left: -80px;
    gap: 20px;
  }
  .tabs {
    width: 100%;
    margin-left: 0;
  }

  .tabs button {
    margin-left: 30px;
  }
}
@media screen and (min-width: 1920px) {
  .spec-item h1 {
    font-size: 28px;
    width: 230px;
  }
  .spec-item p {
    font-size: 18px;
    width: 230px;
  }
  .tabs button {
    font-size: 32px;
  }
  .tabs {
    display: flex;
    align-items: flex-start;
    margin-left: 113px;
  }
  .spec-item p h1 {
    gap: 138px;
    display: flex;
    /* width: 40%; */
    margin-left: 112px;
    margin-bottom: 12px;
  }
  .cOntainer {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    margin-bottom: 100px;
  }
}
