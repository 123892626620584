.footer {
  width: 100%;
  height: 100%;
  color: black;
  padding: 4rem 0;
  padding-top: 50px;
  padding-bottom: 15px;
  /* background-color: #f5f7f9; */
  background-color: #f5f7f9;
}

.footer .container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(5, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  gap: 4rem;
  /* margin-left: 325px; */
  padding: 60px 0;
}

/* .footer h4 {
	display: inline-block;
	border-bottom: 3px solid var(--primary-blue);
	padding-bottom: 0.2rem;
} */

.footer .col p {
  margin: 1rem 0;
  font-size: 14px;
  line-height: 1.5rem;
}
.footer .col h4 {
  font-size: 22px;
  line-height: 114%;
  color: #151515;
}
@media screen and (max-width: 940px) {
  .footer .container {
    grid-template-columns: repeat(2, 1fr);
  }
  /* 
	.footer h4 {
		margin-top: 2rem;
	} */
}
/* .col {
padding-left: 550pxz;
} */
/* .wrapper {
	text-align: start;
} */
.footer__logo-content {
  color: black;
  margin-top: 15px;
  line-height: 30px;
}
.quick__link {
  border: none !important;
  background: transparent !important;
}

.quick__link a {
  color: black;
  text-decoration: none;
  font-weight: 300;
  transition: 0.3s;
}

.quick__link a:hover {
  color: #f9a826;
}
/* .section-grid {
	display: grid;
	grid-template-columns: 1fr;
	column-gap: 50px;
	row-gap: 50px;
	padding: 4rem;
} */
/* .col {
	text-align: initial;
} */
/* .container {
	border-bottom: 1px solid #ccc;
}
.footert {
	border-bottom: 1px solid #ccc;
	margin-left: 325px;
	display: flex;
} */
.container {
  border-bottom: 1px solid #ccc;
  width: 100%;
}
/* .footer__bottom {
	border-top: 1px solid #7c8a972f;
	display: flex;
	justify-content: space-around;
} */
.desc {
  font-size: 12px;
  flex-wrap: wrap;
  /* position: fixed;
	background-color: gray;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 8px;
	text-align: center; */
}
.form__group {
  width: 30%;
  margin-bottom: 0;
  display: flex;
  background: #fff;
}
/* .bottom {
	/* display: block;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px; */
.bottom {
  color: #a0a3a7;
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  /* border-bottom: 1px solid #ccc; */
  /* margin-top: 33px;
	margin-bottom: 33px;
	margin-left: 200px;
	height: 14px; */
  margin-block: 33px;
}
.bottom p {
  float: left;
  /* gap: 22px; */
}
.mership {
  color: #a0a3a7;
  max-width: 1400px;
  gap: 19px;
  /* margin-left: 200px; */
}
.details {
  max-width: 1140px;
  margin: auto;
  /* padding: 1rem; */
}

.gap {
  /* margin-left: 369px; */
  font-family: "General Sans";
}

@media screen and (max-width: 770px) {
  .footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .details {
    display: flex;
    flex-direction: column;
  }
  .bottom {
    display: flex;
    flex-direction: column;
  }
  .mership {
    width: 100%;
    /* margin-left: 0; */
  }
  .gap {
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (min-width: 1920px) {
  .footer .container h4 {
    font-size: 28px;
  }
  .footer .container p {
    margin-top: 40px;
    font-size: 18px;
  }
  .bottom p {
    font-size: 16px;
  }
}
